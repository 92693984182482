import { createContext } from 'react'

interface ModalContextProps {
  openedStatus: Record<number, boolean>
  setOpened: (index: number | undefined, value: boolean) => number
  unmountModal: (index: number) => void
  setDuration: (index: number) => void
}

export const ModalContext = createContext<ModalContextProps>({
  openedStatus: [],
  setOpened: () => 0,
  unmountModal: () => {},
  setDuration: () => {},
})
