import { OptionTreeItem } from 'interfaces/components.interfaces'

export const flattenOptionTree = (
  optionTreeItems?: OptionTreeItem[],
): { label: string; value?: string | number | null }[] => {
  if (!optionTreeItems) {
    return []
  }
  const flattenedItems: { label: string; value?: string | number | null }[] = []

  function flatten(item: OptionTreeItem): void {
    flattenedItems.push({
      label: String(item.label),
      value: item.value ?? null,
    })

    if (item.items) {
      item.items.forEach(flatten)
    }
  }

  optionTreeItems.forEach(flatten)

  return flattenedItems
}
