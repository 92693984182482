import { FC, ReactNode } from 'react'

interface ItemsTreeParseItemProps {
  className?: string
  children?: ReactNode
}

export const ItemsTreeParseItem: FC<ItemsTreeParseItemProps> = ({ className, children }) => (
  <div className={className}>{children}</div>
)
