import { cloneDeep, isEqual } from 'lodash'
import { shallowCompareArrays } from 'utils/shallowCompareArrays'

const cache = new WeakMap()

export const memoizeByObject = <T extends object, U>(obj: T | null | undefined, result: U): U => {
  if (!obj) {
    return result
  }
  if (cache.has(obj)) {
    return cache.get(obj)
  }
  cache.set(obj, result)
  return result
}

export const memoizeCallbackByObject = <T extends object, U>(obj: T | null | undefined, cb: () => U): U => {
  if (!obj) {
    return cb()
  }
  if (cache.has(obj)) {
    return cache.get(obj)
  }
  cache.set(obj, cb())
  return cb()
}

const cacheByPrimitive = new Map()

export const memoizeByPrimitive = <T, U>(primitive: T, result: U): U => {
  if (cacheByPrimitive.has(primitive)) {
    return cacheByPrimitive.get(primitive)
  }
  cacheByPrimitive.set(primitive, result)
  return result
}

export const memoizeCallbackByPrimitive = <T, U>(primitive: T, cb: () => U): U => {
  if (cacheByPrimitive.has(primitive)) {
    return cacheByPrimitive.get(primitive)
  }
  cacheByPrimitive.set(primitive, cb())
  return cb()
}

export const cacheForCallback = new Map()

export const memoizeCallback = <U>(
  cb: () => U,
  key: string,
  deps: any[],
  options?: {
    isIgnoredValue?: (value: U) => boolean
    deepCompare?: any[]
    deepCompareFn?: (oldDeepCompare?: any[], newDeepCompare?: any[]) => boolean
    conditionFn?: () => boolean
  },
): U => {
  const deepCompareFn = options?.deepCompareFn || isEqual
  let value: U
  const isNotIgnored = () => typeof value !== 'undefined' || !options?.isIgnoredValue?.(value)
  if (cacheForCallback.has(key)) {
    const item = cacheForCallback.get(key) as { value: any; deps: any[]; deepCompare?: any[] }[]
    const found = item.find(({ deps: itemDeps }) => shallowCompareArrays(itemDeps, deps))
    if (found && (!options?.deepCompare || deepCompareFn(found.deepCompare, options?.deepCompare))) {
      return found.value
    }
    if (options?.conditionFn?.() === false) {
      return undefined as U
    }
    value = cb()
    if (isNotIgnored()) {
      if (!found) {
        item.push({ value, deps, deepCompare: cloneDeep(options?.deepCompare) })
      } else {
        found.deepCompare = cloneDeep(options?.deepCompare)
        found.value = value
      }
    }
  } else {
    value = cb()
    if (isNotIgnored()) {
      cacheForCallback.set(key, [{ value, deps, deepCompare: cloneDeep(options?.deepCompare) }])
    }
  }

  return value
}
