export const renderTicks = (counter = 20) =>
  new Promise((resolve) => {
    requestAnimationFrame(async () => {
      if (!counter) {
        resolve(1)
      } else {
        renderTicks(counter - 1).then(resolve)
      }
    })
  })
