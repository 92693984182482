import { ForwardedRef, MutableRefObject, RefObject, useCallback } from 'react'

export const useCombinedRef = <T = HTMLElement>(
  ...refs: (RefObject<T> | MutableRefObject<T | null> | ForwardedRef<T> | undefined)[]
) => {
  const cbRef = useCallback((element: T | null) => {
    refs.forEach((ref) => {
      if (!ref) {
        return
      }
      if (typeof ref === 'function') {
        ref(element)
      } else {
        ;(ref as MutableRefObject<T | null>).current = element
      }
    })
  }, refs)
  return { cbRef }
}
