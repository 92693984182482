import { useContext, useEffect, useState } from 'react'

import { ModalContext } from 'context/ModalContext'

export const useDisabledScroll = (isOpened?: boolean, duration = 200, ready = true) => {
  const [indexPopup, setIndexPopup] = useState<number>()
  const { setOpened, unmountModal, setDuration } = useContext(ModalContext)

  useEffect(() => {
    if (!ready) {
      if (indexPopup) {
        unmountModal(indexPopup)
      }
      return
    }
    setTimeout(() => {
      const updatedIndex = setOpened(indexPopup, !!isOpened)
      setIndexPopup(updatedIndex)
      setDuration(duration)
    })

    return () => {
      if (indexPopup) {
        unmountModal(indexPopup)
      }
    }
  }, [isOpened, ready])
}
